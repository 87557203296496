/* EVENTS */
export const GET_CAR_MODELS = "GET_CAR_MODELS";
export const GET_CAR_MODELS_SUCCESS = "GET_CAR_MODELS_SUCCESS";
export const GET_CAR_MODELS_FAIL = "GET_CAR_MODELS_FAIL";

export const ADD_NEW_CAR_MODEL = "ADD_NEW_CAR_MODEL";
export const ADD_CAR_MODEL_SUCCESS = "ADD_CAR_MODEL_SUCCESS";
export const ADD_CAR_MODEL_FAIL = "ADD_CAR_MODEL_FAIL";

export const UPDATE_CAR_MODEL = "UPDATE_CAR_MODEL";
export const UPDATE_CAR_MODEL_SUCCESS = "UPDATE_CAR_MODEL_SUCCESS";
export const UPDATE_CAR_MODEL_FAIL = "UPDATE_CAR_MODEL_FAIL";

export const DELETE_CAR_MODEL = "DELETE_CAR_MODEL";
export const DELETE_CAR_MODEL_SUCCESS = "DELETE_CAR_MODEL_SUCCESS";
export const DELETE_CAR_MODEL_FAIL = "DELETE_CAR_MODEL_FAIL";

export const DELETE_ALL_CAR_MODEL = "DELETE_ALL_CAR_MODEL";
export const DELETE_ALL_CAR_MODEL_SUCCESS = "DELETE_ALL_CAR_MODEL_SUCCESS";
export const DELETE_ALL_CAR_MODEL_FAIL = "DELETE_ALL_CAR_MODEL_FAIL";

export const GET_CAR_VARIANTS_FROM_CARMODEL = "GET_CAR_VARIANTS_FROM_CARMODEL";
export const GET_CAR_VARIANTS_FROM_CARMODEL_SUCCESS = "GET_CAR_VARIANTS_FROM_CARMODEL_SUCCESS";
export const GET_CAR_VARIANTS_FROM_CARMODEL_FAIL = "GET_CAR_VARIANTS_FROM_CARMODEL_FAIL";

export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_COUNTRIES_LIST_SUCCESS = "GET_COUNTRIES_LIST_SUCCESS";
export const GET_COUNTRIES_LIST_ERROR = "GET_COUNTRIES_LIST_ERROR";
