/* EVENTS */
export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const GET_TESTIMONIALS_SUCCESS = "GET_TESTIMONIALS_SUCCESS";
export const GET_TESTIMONIALS_FAIL = "GET_TESTIMONIALS_FAIL";

export const ADD_NEW_TESTIMONIAL = "ADD_NEW_TESTIMONIAL";
export const ADD_TESTIMONIAL_SUCCESS = "ADD_TESTIMONIAL_SUCCESS";
export const ADD_TESTIMONIAL_FAIL = "ADD_TESTIMONIAL_FAIL";

export const UPDATE_TESTIMONIAL = "UPDATE_TESTIMONIAL";
export const UPDATE_TESTIMONIAL_SUCCESS = "UPDATE_TESTIMONIAL_SUCCESS";
export const UPDATE_TESTIMONIAL_FAIL = "UPDATE_TESTIMONIAL_FAIL";

export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL";
export const DELETE_TESTIMONIAL_SUCCESS = "DELETE_TESTIMONIAL_SUCCESS";
export const DELETE_TESTIMONIAL_FAIL = "DELETE_TESTIMONIAL_FAIL";

export const DELETE_ALL_TESTIMONIAL = "DELETE_ALL_TESTIMONIAL";
export const DELETE_ALL_TESTIMONIAL_SUCCESS = "DELETE_ALL_TESTIMONIAL_SUCCESS";
export const DELETE_ALL_TESTIMONIAL_FAIL = "DELETE_ALL_TESTIMONIAL_FAIL";
