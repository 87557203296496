/* EVENTS */
export const GET_CAR_DEALERS = "GET_CAR_DEALERS";
export const GET_CAR_DEALERS_SUCCESS = "GET_CAR_DEALERS_SUCCESS";
export const GET_CAR_DEALERS_FAIL = "GET_CAR_DEALERS_FAIL";

export const ADD_NEW_CAR_DEALER = "ADD_NEW_CAR_DEALER";
export const ADD_CAR_DEALER_SUCCESS = "ADD_CAR_DEALER_SUCCESS";
export const ADD_CAR_DEALER_FAIL = "ADD_CAR_DEALER_FAIL";

export const UPDATE_CAR_DEALER = "UPDATE_CAR_DEALER";
export const UPDATE_CAR_DEALER_SUCCESS = "UPDATE_CAR_DEALER_SUCCESS";
export const UPDATE_CAR_DEALER_FAIL = "UPDATE_CAR_DEALER_FAIL";

export const DELETE_CAR_DEALER = "DELETE_CAR_DEALER";
export const DELETE_CAR_DEALER_SUCCESS = "DELETE_CAR_DEALER_SUCCESS";
export const DELETE_CAR_DEALER_FAIL = "DELETE_CAR_DEALER_FAIL";

export const DELETE_ALL_CAR_DEALER = "DELETE_ALL_CAR_DEALER";
export const DELETE_ALL_CAR_DEALER_SUCCESS = "DELETE_ALL_CAR_DEALER_SUCCESS";
export const DELETE_ALL_CAR_DEALER_FAIL = "DELETE_ALL_CAR_DEALER_FAIL";
