// Collections
export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS";
export const GET_COLLECTIONS_FAIL = "GET_COLLECTIONS";
export const ADD_COLLECTION = "ADD_COLLECTION";
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";
export const ADD_COLLECTION_FAIL = "ADD_COLLECTION_FAIL";
export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export const UPDATE_COLLECTION_SUCCESS = "UPDATE_COLLECTION_SUCCESS";
export const UPDATE_COLLECTION_FAIL = "UPDATE_COLLECTION_FAIL";
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAIL = "DELETE_COLLECTION_FAIL";