/* EVENTS */
export const GET_CAR_BLOGS = "GET_CAR_BLOGS";
export const GET_CAR_BLOGS_SUCCESS = "GET_CAR_BLOGS_SUCCESS";
export const GET_CAR_BLOGS_FAIL = "GET_CAR_BLOGS_FAIL";

export const ADD_NEW_CAR_BLOG = "ADD_NEW_CAR_BLOG";
export const ADD_CAR_BLOG_SUCCESS = "ADD_CAR_BLOG_SUCCESS";
export const ADD_CAR_BLOG_FAIL = "ADD_CAR_BLOG_FAIL";

export const UPDATE_CAR_BLOG = "UPDATE_CAR_BLOG";
export const UPDATE_CAR_BLOG_SUCCESS = "UPDATE_CAR_BLOG_SUCCESS";
export const UPDATE_CAR_BLOG_FAIL = "UPDATE_CAR_BLOG_FAIL";

export const DELETE_CAR_BLOG = "DELETE_CAR_BLOG";
export const DELETE_CAR_BLOG_SUCCESS = "DELETE_CAR_BLOG_SUCCESS";
export const DELETE_CAR_BLOG_FAIL = "DELETE_CAR_BLOG_FAIL";

export const DELETE_ALL_CAR_BLOG = "DELETE_ALL_CAR_BLOG";
export const DELETE_ALL_CAR_BLOG_SUCCESS = "DELETE_ALL_CAR_BLOG_SUCCESS";
export const DELETE_ALL_CAR_BLOG_FAIL = "DELETE_ALL_CAR_BLOG_FAIL";

export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_COUNTRIES_LIST_SUCCESS = "GET_COUNTRIES_LIST_SUCCESS";
export const GET_COUNTRIES_LIST_ERROR = "GET_COUNTRIES_LIST_ERROR";

export const GET_CAR_MODEL_BY_BRAND = "GET_CAR_MODEL_BY_BRAND";
export const GET_CAR_MODEL_BY_BRAND_SUCCESS = "GET_CAR_MODEL_BY_BRAND_SUCCESS";
export const GET_CAR_MODEL_BY_BRAND_FAILED = "GET_CAR_MODEL_BY_BRAND_FAILED";
