/* EVENTS */
export const GET_CAR_CUSTOMERS = "GET_CAR_CUSTOMERS";
export const GET_CAR_CUSTOMERS_SUCCESS = "GET_CAR_CUSTOMERS_SUCCESS";
export const GET_CAR_CUSTOMERS_FAIL = "GET_CAR_CUSTOMERS_FAIL";

export const ADD_NEW_CAR_CUSTOMER = "ADD_NEW_CAR_CUSTOMER";
export const ADD_CAR_CUSTOMER_SUCCESS = "ADD_CAR_CUSTOMER_SUCCESS";
export const ADD_CAR_CUSTOMER_FAIL = "ADD_CAR_CUSTOMER_FAIL";

export const UPDATE_CAR_CUSTOMER = "UPDATE_CAR_CUSTOMER";
export const UPDATE_CAR_CUSTOMER_SUCCESS = "UPDATE_CAR_CUSTOMER_SUCCESS";
export const UPDATE_CAR_CUSTOMER_FAIL = "UPDATE_CAR_CUSTOMER_FAIL";

export const DELETE_CAR_CUSTOMER = "DELETE_CAR_CUSTOMER";
export const DELETE_CAR_CUSTOMER_SUCCESS = "DELETE_CAR_CUSTOMER_SUCCESS";
export const DELETE_CAR_CUSTOMER_FAIL = "DELETE_CAR_CUSTOMER_FAIL";

export const DELETE_ALL_CAR_CUSTOMER = "DELETE_ALL_CAR_CUSTOMER";
export const DELETE_ALL_CAR_CUSTOMER_SUCCESS = "DELETE_ALL_CAR_CUSTOMER_SUCCESS";
export const DELETE_ALL_CAR_CUSTOMER_FAIL = "DELETE_ALL_CAR_CUSTOMER_FAIL";

export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_COUNTRIES_LIST_SUCCESS = "GET_COUNTRIES_LIST_SUCCESS";
export const GET_COUNTRIES_LIST_ERROR = "GET_COUNTRIES_LIST_ERROR";
