/* EVENTS */
export const GET_CAR_BRANDS = "GET_CAR_BRANDS";
export const GET_CAR_BRANDS_SUCCESS = "GET_CAR_BRANDS_SUCCESS";
export const GET_CAR_BRANDS_FAIL = "GET_CAR_BRANDS_FAIL";

export const ADD_NEW_CAR_BRAND = "ADD_NEW_CAR_BRAND";
export const ADD_CAR_BRAND_SUCCESS = "ADD_CAR_BRAND_SUCCESS";
export const ADD_CAR_BRAND_FAIL = "ADD_CAR_BRAND_FAIL";

export const UPDATE_CAR_BRAND = "UPDATE_CAR_BRAND";
export const UPDATE_CAR_BRAND_SUCCESS = "UPDATE_CAR_BRAND_SUCCESS";
export const UPDATE_CAR_BRAND_FAIL = "UPDATE_CAR_BRAND_FAIL";

export const DELETE_CAR_BRAND = "DELETE_CAR_BRAND";
export const DELETE_CAR_BRAND_SUCCESS = "DELETE_CAR_BRAND_SUCCESS";
export const DELETE_CAR_BRAND_FAIL = "DELETE_CAR_BRAND_FAIL";

export const DELETE_ALL_CAR_BRAND = "DELETE_ALL_CAR_BRAND";
export const DELETE_ALL_CAR_BRAND_SUCCESS = "DELETE_ALL_CAR_BRAND_SUCCESS";
export const DELETE_ALL_CAR_BRAND_FAIL = "DELETE_ALL_CAR_BRAND_FAIL";

export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_COUNTRIES_LIST_SUCCESS = "GET_COUNTRIES_LIST_SUCCESS";
export const GET_COUNTRIES_LIST_ERROR = "GET_COUNTRIES_LIST_ERROR";
