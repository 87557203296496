/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

/* PRODUCT LIST */
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCTS_LIST_FAIL = "GET_PRODUCTS_LIST_FAIL";

export const ADD_NEW_PRODUCT_IN_LIST = "ADD_NEW_PRODUCT_IN_LIST";
export const ADD_PRODUCT_IN_LIST_SUCCESS = "ADD_PRODUCT_IN_LIST_SUCCESS";
export const ADD_PRODUCT_IN_LIST_FAIL = "ADD_PRODUCT_IN_LIST_FAIL";

export const UPDATE_PRODUCT_IN_LIST = "UPDATE_PRODUCT_IN_LIST";
export const UPDATE_PRODUCT_IN_LIST_SUCCESS = "UPDATE_PRODUCT_IN_LIST_SUCCESS";
export const UPDATE_PRODUCT_IN_LIST_FAIL = "UPDATE_PRODUCT_IN_LIST_FAIL";

export const DELETE_PRODUCT_IN_LIST = "DELETE_PRODUCT_IN_LIST";
export const DELETE_PRODUCT_IN_LIST_SUCCESS = "DELETE_PRODUCT_IN_LIST_SUCCESS";
export const DELETE_PRODUCT_IN_LIST_FAIL = "DELETE_PRODUCT_IN_LIST_FAIL";

/* PRODUCT DETAILS */
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"

export const GET_PRODUCT_COMMENTS = "GET_PRODUCT_COMMENTS"
export const GET_PRODUCT_COMMENTS_SUCCESS = "GET_PRODUCT_COMMENTS_SUCCESS"
export const GET_PRODUCT_COMMENTS_FAIL = "GET_PRODUCT_COMMENTS_FAIL"

export const ON_LIKE_COMMENT = "ON_LIKE_COMMENT"
export const ON_LIKE_COMMENT_SUCCESS = "ON_LIKE_COMMENT_SUCCESS"
export const ON_LIKE_COMMENT_FAIL = "ON_LIKE_COMMENT_FAIL"

export const ON_LIKE_REPLY = "ON_LIKE_REPLY"
export const ON_LIKE_REPLY_SUCCESS = "ON_LIKE_REPLY_SUCCESS"
export const ON_LIKE_REPLY_FAIL = "ON_LIKE_REPLY_FAIL"

export const ON_ADD_REPLY = "ON_ADD_REPLY"
export const ON_ADD_REPLY_SUCCESS = "ON_ADD_REPLY_SUCCESS"
export const ON_ADD_REPLY_FAIL = "ON_ADD_REPLY_FAIL"

export const ON_ADD_COMMENT = "ON_ADD_COMMENT"
export const ON_ADD_COMMENT_SUCCESS = "ON_ADD_COMMENT_SUCCESS"
export const ON_ADD_COMMENT_FAIL = "ON_ADD_COMMENT_FAIL"

/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

/**
 * add ORDER
 */
export const ADD_NEW_ORDER = "ADD_NEW_ORDER"
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS"
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL"

/**
 * Edit ORDER
 */
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

/**
 * Delete ORDER
 */
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL"

/**
 * 
 * Delete All orders
 */
export const DELETE_ALL_ORDERS = "DELETE_ALL_ORDERS";
export const DELETE_ALL_ORDER_SUCCESS = "DELETE_ALL_ORDER_SUCCESS";
export const DELETE_ALL_ORDER_FAIL = "DELETE_ALL_ORDER_FAIL";

/* CART DATA */
export const GET_CART_DATA = "GET_CART_DATA"
export const GET_CART_DATA_SUCCESS = "GET_CART_DATA_SUCCESS"
export const GET_CART_DATA_FAIL = "GET_CART_DATA_FAIL"

/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

/**
 * add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL"

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"

// Import customers
export const IMPORT_CUSTOMERS = "IMPORT_CUSTOMERS";
export const IMPORT_CUSTOMERS_SUCCESS = "IMPORT_CUSTOMERS_SUCCESS";
export const IMPORT_CUSTOMERS_FAIL = "IMPORT_CUSTOMERS_FAIL";

// Delete all customers
export const DELETE_ALL_CUSTOMERS = "DELETE_ALL_CUSTOMERS";
export const DELETE_ALL_CUSTOMERS_SUCCESS = "DELETE_ALL_CUSTOMERS_SUCCESS";
export const DELETE_ALL_CUSTOMERS_FAIL = "DELETE_ALL_CUSTOMERS_FAIL";

/* SHOPS */
export const GET_SHOPS = "GET_SHOPS"
export const GET_SHOPS_SUCCESS = "GET_SHOPS_SUCCESS"
export const GET_SHOPS_FAIL = "GET_SHOPS_FAIL"

/*INVENTORY*/
export const GET_INVENTORY = "GET_INVENTORY";
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS";
export const GET_INVENTORY_FAIL = "GET_INVENTORY_FAIL";

export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const UPDATE_INVENTORY_SUCCESS = "UPDATE_INVENTORY_SUCCESS";
export const UPDATE_INVENTORY_FAIL = "UPDATE_INVENTORY_FAIL";

/*Customer Details  page*/
/*ORDERS */
export const GET_CUSTOMER_ORDER = "GET_CUSTOMER_ORDER";
export const GET_CUSTOMER_ORDER_SUCCESS = "GET_CUSTOMER_ORDER_SUCCESS";
export const GET_CUSTOMER_ORDER_FAIL = "GET_CUSTOMER_ORDER_FAIL";
/*NOTES*/
export const GET_NOTES = "GET_NOTES";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_FAIL = "GET_NOTES_FAIL";

export const ADD_NOTES = "ADD_NOTES";
export const ADD_NOTES_SUCCESS = "ADD_NOTES_SUCCESS";
export const ADD_NOTES_FAIL = "ADD_NOTES_FAIL";

export const DELETE_ALL_NOTES = "DELETE_ALL_NOTES";
export const DELETE_ALL_NOTES_SUCCESS = "DELETE_ALL_NOTES_SUCCESS";
export const DELETE_ALL_NOTES_FAIL = "DELETE_ALL_NOTES_FAIL";

export const UPDATE_NOTES = "UPDATE_NOTES";
export const UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_FAIL = "UPDATE_NOTES_FAIL";

export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAIL = "DELETE_NOTE_FAIL";

export const GET_CUSTOMER_INVOICE = "GET_CUSTOMER_INVOICE";
export const GET_CUSTOMER_INVOICE_SUCCESS = "GET_CUSTOMER_INVOICE_SUCCESS";
export const GET_CUSTOMER_INVOICE_FAIL = "GET_CUSTOMER_INVOICE_FAIL";

export const DELETE_ALL_INVOICE = "DELETE_ALL_INVOICE";
export const DELETE_ALL_INVOICE_SUCCESS = "DELETE_ALL_INVOICE_SUCCESS";
export const DELETE_ALL_INVOICE_FAIL = "DELETE_ALL_INVOICE_FAIL";

export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAIL = "DELETE_INVOICE_FAIL";

/*Task */
export const GET_TASK = "GET_TASK";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAIL = "GET_TASK_FAIL";

export const ADD_TASK = "ADD_TASK";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_FAIL = "ADD_TASK_FAIL";

export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL";

export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL";

export const DOMAIN_AVAILABILITY = "DOMAIN_AVAILABILITY";
export const DOMAIN_AVAILABILITY_SUCCESS = "DOMAIN_AVAILABILITY_SUCCESS";
export const DOMAIN_AVAILABILITY_FAIL = "DOMAIN_AVAILABILITY_FAIL";

export const DOMAIN_SUGGESTION = "DOMAIN_SUGGESTION";
export const DOMAIN_SUGGESTION_SUCCESS = "DOMAIN_SUGGESTION_SUCCESS";
export const DOMAIN_SUGGESTION_FAIL = "DOMAIN_SUGGESTION_FAIL";

export const DOMAIN_CLEAN_UP = "DOMAIN_CLEAN_UP";

export const BUY_DOMAIN = "BUY_DOMAIN";
export const BUY_DOMAIN_SUCCESS = "BUY_DOMAIN_SUCCESS";
export const BUY_DOMAIN_FAIL = "BUY_DOMAIN_FAIL";

export const DOMAIN_ORDERID = "DOMAIN_ORDERID";
export const DOMAIN_ORDERID_SUCCESS = "DOMAIN_ORDERID_SUCCESS";
export const DOMAIN_ORDERID_FAIL = "DOMAIN_ORDERID_FAIL";
export const DOMAIN_PAYMENT_CLEANUP = "DOMAIN_PAYMENT_CLEANUP";

