/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const GET_ORDERS_COUNT_DATA = "GET_ORDERS_COUNT_DATA";
export const GET_ORDERS_COUNT_DATA_SUCCESS = "GET_ORDERS_COUNT_DATA_SUCCESS";
export const GET_ORDERS_COUNT_DATA_ERROR = "GET_ORDERS_COUNT_DATA_ERROR";
export const GET_REVENUE_TOTAL_DATA = "GET_REVENUE_TOTAL_DATA";
export const GET_REVENUE_TOTAL_DATA_SUCCESS = "GET_REVENUE_TOTAL_DATA_SUCCESS";
export const GET_REVENUE_TOTAL_DATA_ERROR = "GET_REVENUE_TOTAL_DATA_ERROR";
export const GET_ORDER_TOTAL_AVERAGE_PRICE = "GET_ORDER_TOTAL_AVERAGE_PRICE";
export const GET_ORDER_TOTAL_AVERAGE_PRICE_SUCCESS = "GET_ORDER_TOTAL_AVERAGE_PRICE_SUCCESS";
export const GET_ORDER_TOTAL_AVERAGE_PRICE_ERROR = "GET_ORDER_TOTAL_AVERAGE_PRICE_ERROR";

export const CAR_DASHBOARD_ACTIVITY_COUNT = "CAR_DASHBOARD_ACTIVITY_COUNT";
export const CAR_DASHBOARD_ACTIVITY_COUNT_SUCCESS = "CAR_DASHBOARD_ACTIVITY_COUNT_SUCCESS";
export const CAR_DASHBOARD_ACTIVITY_COUNT_ERROR = "CAR_DASHBOARD_ACTIVITY_COUNT_ERROR";

export const CAR_SEARCHES_DASHBOARD_LIST = "CAR_SEARCHES_DASHBOARD_LIST"
export const CAR_SEARCHES_DASHBOARD_LIST_SUCCESS = "CAR_SEARCHES_DASHBOARD_LIST_SUCCESS"
export const CAR_SEARCHES_DASHBOARD_LIST_ERROR = "CAR_SEARCHES_DASHBOARD_LIST_ERROR"
