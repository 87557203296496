export const GET_CAR_BANNER_IMAGE = "v1/carbanner/get/banner";
export const ADD_CAR_BANNER_IMAGE = 'v1/carbanner/add/banner';

export const GET_CAR_DASHBOARD_ACTIVITY = "v1/cardashboard/get/dashboard/total-list";
export const GET_CAR_TOP_SEARCH_ACTIVITY = "v1/carcustomer/get/customers/top-cars-list";

export const GET_CAR_BRANDS = "v1/automobile/get/carbrand/submitted/all";
export const ADD_CAR_BRAND = "/v1/automobile/add/carbrand";
export const UPDATE_CAR_BRAND = "/v1/automobile/update/carbrand";
export const DELETE_CAR_BRAND = "/v1/automobile/delete/carbrand";
export const DELETE_ALL_CAR_BRAND = "/v1/automobile/delete/all/carbrands";

export const GET_CAR_MODELS = "v1/carmodel/get/submitted/all";
export const ADD_CAR_MODEL = "/v1/carmodel/add/carbrand";
export const UPDATE_CAR_MODEL = "/v1/carmodel/update";
export const DELETE_CAR_MODEL = "/v1/carmodel/delete";
export const DELETE_ALL_CAR_MODEL = "/v1/carmodel/delete/all";
export const GET_CAR_VARIANTS_FROM_CAR_MODEL = "v1/carvariant/from/carmodel";

export const GET_CAR_VARIANTS = "v1/carvariant/get/submitted/all";
export const GET_CAR_VARIANT_PRICINGS = "v1/carvariantpricing/get/variant";
export const DELETE_CAR_VARIANT_PRICINGS = "v1/carvariantpricing/deleteAll";
export const ADD_CAR_VARIANT = "/v1/carvariant/add/carmodel";
export const UPDATE_CAR_VARIANT = "/v1/carvariant/update";
export const DELETE_CAR_VARIANT = "/v1/carvariant/delete";
export const DELETE_ALL_CAR_VARIANT = "/v1/carmodel/delete/all";
export const GET_COUNTRIES_LIST_DATA = "https://countriesnow.space/api/v0.1/countries";

export const GET_CAR_BLOGS = "v1/carblog/get/carblog/submitted/all";
export const ADD_CAR_BLOG = "/v1/carblog/add/blog";
export const UPDATE_CAR_BLOG = "/v1/carblog/update/blog";
export const DELETE_CAR_BLOG = "/v1/carblog/delete/blog";
export const DELETE_ALL_CAR_BLOG = "/v1/carblog/delete/all/blogs";

export const GET_CAR_CUSTOMERS = "v1/carcustomer/get/customer/submitted/all";
export const ADD_CAR_CUSTOMER = "/v1/carblog/add/blog";
export const UPDATE_CAR_CUSTOMER = "/v1/automobile/update/carblog";
export const DELETE_CAR_CUSTOMER = "/v1/carcustomer/delete/customer";
export const DELETE_ALL_CAR_CUSTOMER = "/v1/carcustomer/delete/all/customers";

export const GET_CAR_DEALERS = "v1/cardealer/get/submitted/all";
export const ADD_CAR_DEALER = "/v1/cardealer/add/cardealer";
export const UPDATE_CAR_DEALER = "/v1/cardealer/update/cardealer";
export const DELETE_CAR_DEALER = "/v1/cardealer/delete/dealer";
export const DELETE_ALL_CAR_DEALER = "/v1/cardealer/delete/all";

export const GET_MODEL_BY_BRAND = "/v1/automobile/carmodel/from/carbrand";

export const ADD_CAR_VARIANT_PRICING = "/v1/carvariantpricing/add/carvariant";
