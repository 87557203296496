export const DOMAIN_AVAILABILITY = "DOMAIN_AVAILABILITY";
export const DOMAIN_AVAILABILITY_SUCCESS = "DOMAIN_AVAILABILITY_SUCCESS";
export const DOMAIN_AVAILABILITY_FAIL = "DOMAIN_AVAILABILITY_FAIL";

export const DOMAIN_SUGGESTION = "DOMAIN_SUGGESTION";
export const DOMAIN_SUGGESTION_SUCCESS = "DOMAIN_SUGGESTION_SUCCESS";
export const DOMAIN_SUGGESTION_FAIL = "DOMAIN_SUGGESTION_FAIL";

export const DOMAIN_CLEAN_UP = "DOMAIN_CLEAN_UP";

export const BUY_DOMAIN = "BUY_DOMAIN";
export const BUY_DOMAIN_SUCCESS = "BUY_DOMAIN_SUCCESS";
export const BUY_DOMAIN_FAIL = "BUY_DOMAIN_FAIL";

export const DOMAIN_ORDERID = "DOMAIN_ORDERID";
export const DOMAIN_ORDERID_SUCCESS = "DOMAIN_ORDERID_SUCCESS";
export const DOMAIN_ORDERID_FAIL = "DOMAIN_ORDERID_FAIL";
export const DOMAIN_PAYMENT_CLEANUP = "DOMAIN_PAYMENT_CLEANUP";
