/* EVENTS */
export const GET_CAR_VARIANTS = "GET_CAR_VARIANTS";
export const GET_CAR_VARIANTS_SUCCESS = "GET_CAR_VARIANTS_SUCCESS";
export const GET_CAR_VARIANTS_FAIL = "GET_CAR_VARIANTS_FAIL";

export const ADD_NEW_CAR_VARIANT = "ADD_NEW_CAR_VARIANT";
export const ADD_CAR_VARIANT_SUCCESS = "ADD_CAR_VARIANT_SUCCESS";
export const ADD_CAR_VARIANT_FAIL = "ADD_CAR_VARIANT_FAIL";

export const UPDATE_CAR_VARIANT = "UPDATE_CAR_VARIANT";
export const UPDATE_CAR_VARIANT_SUCCESS = "UPDATE_CAR_VARIANT_SUCCESS";
export const UPDATE_CAR_VARIANT_FAIL = "UPDATE_CAR_VARIANT_FAIL";

export const GET_CAR_VARIANT_PRICING = "GET_CAR_VARIANT_PRICING";
export const GET_CAR_VARIANT_PRICING_SUCCESS = "GET_CAR_VARIANT_PRICING_SUCCESS";
export const GET_CAR_VARIANT_PRICING_FAIL = "GET_CAR_VARIANT_PRICING_FAIL";

export const DELETE_CAR_VARIANT_PRICING = "DELETE_CAR_VARIANT_PRICING";
export const DELETE_CAR_VARIANT_PRICING_SUCCESS = "DELETE_CAR_VARIANT_PRICING_SUCCESS";
export const DELETE_CAR_VARIANT_PRICING_FAIL = "DELETE_CAR_VARIANT_PRICING_FAIL";

export const DELETE_CAR_VARIANT = "DELETE_CAR_VARIANT";
export const DELETE_CAR_VARIANT_SUCCESS = "DELETE_CAR_VARIANT_SUCCESS";
export const DELETE_CAR_VARIANT_FAIL = "DELETE_CAR_VARIANT_FAIL";

export const DELETE_ALL_CAR_VARIANT = "DELETE_ALL_CAR_VARIANT";
export const DELETE_ALL_CAR_VARIANT_SUCCESS = "DELETE_ALL_CAR_VARIANT_SUCCESS";
export const DELETE_ALL_CAR_VARIANT_FAIL = "DELETE_ALL_CAR_VARIANT_FAIL";

export const ADD_ALL_VARIANT_PRICING = "ADD_ALL_VARIANT_PRICING";
export const ADD_ALL_VARIANT_PRICING_SUCCESS = "ADD_ALL_VARIANT_PRICING_SUCCESS";
export const ADD_ALL_VARIANT_PRICING_ERROR = "ADD_ALL_VARIANT_PRICING_ERROR";

export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_COUNTRIES_LIST_SUCCESS = "GET_COUNTRIES_LIST_SUCCESS";
export const GET_COUNTRIES_LIST_ERROR = "GET_COUNTRIES_LIST_ERROR";
